import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../context/authContext';

function PrivateRoute({ component: Component, ...rest }) {
  const { authTokens } = useAuth();

  function isAuthenticated() {
    if (authTokens) {
      return true;
    }
    return false;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      )}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;

import React from 'react';
import { Redirect } from 'react-router-dom';
import LoginBtn from './LoginBtn';
import { useAuth } from '../context/authContext';

export default function Login() {
  const { authTokens } = useAuth();

  const styleFix = {
    'textAlign': 'center',
  };

  const imgStyle = {
    padding: '15px',
  };

  if (authTokens) {
    return <Redirect to="/" />;
  }

  return (
    <div style={styleFix}>
      <img
        src="/logo.png"
        alt=""
        style={imgStyle}
      />
      <h2>Inventory Dataset Browser Login Page</h2>
      <h4>Please login with your state account</h4>
      <LoginBtn />
    </div>
  );
}

import React, { Component } from "react";
import * as d3 from "d3";
import * as d3Fetch from "d3-fetch";

import HorizontalBarChart from "./Components/HorizontalBarChart";
import BubbleChart from "./Components/BubbleChart";
import Legend from "./Components/Legend";
import NodeDetails from "./Components/NodeDetails";
import BubbleChartControl from "./Components/BubbleChartControl";
import DataSetTable from "./Components/DataSetTable";
import NavBar from "./Components/NavBar";

import { createDimension, initCrossFilter } from "./Util/DataManager";
import AuthContext from '../../context/authContext.js';

class DatasetBrowser extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      xf: null,
      isLoading: true,
      group1: null,
      group2: null,
      group3: null,
      color: null,
      sortBy: 0,
      colorBy: 0,
      clusterBy: 0,
      layoutBy: 0,
      displayNo: 1,
      action: ""
    };

    this.scrollLocation = 1;

    this.colorBy = ["NONE", "system_type_name"];
    this.displayOptions = ["None", "25", "50", "100", "250", "500", "All"];
    this.displayOptionsRows = [0, 25, 50, 100, 250, 500, "Infinity"];
    this.sortKeys = ["NONE", "system_type_name", "organization_name", "division_name"];

    this.handleClick = this.handleClick.bind(this);
    this.handleBubbleControl = this.handleBubbleControl.bind(this);
    this.clickNode = this.clickNode.bind(this);
    this.scrollToDataSet = this.scrollToDataSet.bind(this);
    this.changeDisplayOption = this.changeDisplayOption.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  changeDisplayOption(o) {
    this.setState({ displayNo: o });
  }

  handleBubbleControl(group, option) {
    let obj = { action: "BUBBLE_CONTROL_CHANGE" };
    if (group === 0) {
      obj.colorBy = option;
      this.updateColorBy(option);
    }
    if (group === 1) {
      obj.clusterBy = option;
      if (option === 1) obj.clusterByDim = this.dim1;
      if (option === 2) obj.clusterByDim = this.dim2;
      if (option === 3) obj.clusterByDim = this.dim5;
      //if (option == 4) obj.clusterByDim = this.dim4;
    }
    if (group === 2) obj.layoutBy = option;
    if (group === 3) obj.layoutBy = option;
    if (group === 4) {
      if (this.state.sortBy === option) obj.sortBy = -option;
      else obj.sortBy = option;

      this.sortIndex = {};

      if (option === 1)
        this.group1
          .top(Infinity)
          .forEach(d => (this.sortIndex[d.key] = d.value));
      if (option === 2)
        this.group2
          .top(Infinity)
          .forEach(d => (this.sortIndex[d.key] = d.value));
      if (option === 3)
        this.group5
          .top(Infinity)
          .forEach(d => (this.sortIndex[d.key] = d.value));

      let comp = this;

      this.sortReset = 0;

      this.sortFunction = function(a, b) {
        if (comp.sortReset) {
         
        }

        if (comp.state.sortBy > 0) {
          if (comp.sortReset) {
            
          }
          comp.sortReset = 0;
          return (
            comp.sortIndex[b.data[comp.sortKeys[comp.state.sortBy]]] -
            comp.sortIndex[a.data[comp.sortKeys[comp.state.sortBy]]]
          );
        }
        if (comp.state.sortBy < 0) {
          if (comp.sortReset) {
           
          }
          comp.sortReset = 0;
          return (
            comp.sortIndex[a.data[comp.sortKeys[-comp.state.sortBy]]] -
            comp.sortIndex[b.data[comp.sortKeys[-comp.state.sortBy]]]
          );
        }
        comp.sortReset = 0;
        return 0;
      };
    }

    this.setState(obj);
  }

  updateColorBy(option) {
    if (option === 0) {
      this.colorScale = d3.scaleOrdinal().range(["grey"]);

      this.color = function(d) {
        return "grey";
      };

      this.colorByGroup = [
        { key: "Data Set", value: this.mainDim.top(Infinity).length }
      ];

      this.colorByDimension = {
        members: { "Data Set": { filtered: 0 } },
        setFilter: d => null,
        filtered: 0,
        group: function() {
          return {
            top: function(e) {
              return [{ key: "Data Set" }];
            }
          };
        }
      };
    }

    if (option === 1) {
      this.system_typeIndex = {};
      this.system_types = this.group1.all();
      this.system_types.forEach((d, i) => (this.system_typeIndex[d] = i));

      this.colorScale = d3
        .scaleOrdinal()
        .range([
          "#cfff9e",
          "#a56dff",
          "#59da00",
          "#001e7f",
          "#ffd632",
          "#670050",
          "#01fad2",
          "#cd014c",
          "#00a353",
          "#ff66a2",
          "#a19100",
          "#001f3b",
          "#ff9769",
          "#007882",
          "#9e4200",
          "#a7faff",
          "#ffe0b8"
        ]);

      let self = this;

      this.color = function(d) {
        return self.colorScale(d.data.system_type_name);
      };

      this.colorByDimension = this.dim1;
      this.colorByGroup = this.group1.top(Infinity);
    }

    if (option === 2) {
      this.system_typeIndex = {};
      this.system_types = this.group2.all();
      this.system_types.forEach((d, i) => (this.system_typeIndex[d] = i));

      this.colorScale = d3
        .scaleOrdinal()
        .range([
          "#cfff9e",
          "#a56dff",
          "#59da00",
          "#001e7f",
          "#ffd632",
          "#670050",
          "#01fad2",
          "#cd014c",
          "#00a353",
          "#ff66a2",
          "#a19100",
          "#001f3b",
          "#ff9769",
          "#007882",
          "#9e4200",
          "#a7faff",
          "#ffe0b8"
        ]);

      let self = this;

      this.color = function(d) {
        return self.colorScale(d.data.organization_name);
      };

      this.colorByDimension = this.dim2;
      this.colorByGroup = this.group2.top(Infinity);
    }

    if (option === 3) {
      this.system_typeIndex = {};
      this.system_types = this.group5.all();
      this.system_types.forEach((d, i) => (this.system_typeIndex[d] = i));

      this.colorScale = d3
        .scaleOrdinal()
        .range([
          "#cfff9e",
          "#a56dff",
          "#59da00",
          "#001e7f",
          "#ffd632",
          "#670050",
          "#01fad2",
          "#cd014c",
          "#00a353",
          "#ff66a2",
          "#a19100",
          "#001f3b",
          "#ff9769",
          "#007882",
          "#9e4200",
          "#a7faff",
          "#ffe0b8"
        ]);

      let self = this;

      this.color = function(d) {
        return self.colorScale(d.data.division_name);
      };

      this.colorByDimension = this.dim5;
      this.colorByGroup = this.group5.top(Infinity);
    }

    this.setState({
      color: this.color,
      colorScale: this.colorScale,
      colorByGroup: this.colorByGroup,
      colorByDimension: this.colorByDimension
    });
  }

  componentDidMount() {
    let debug = true;
    const apiUrl = process.env.REACT_APP_POSTGRES_API_URL;
    const authTokens = this.context.authTokens;
    let comp = this;
    this.setState({ isLoading: true });
    this.offline = false;
    window.d3Fetch = d3Fetch;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authTokens}`,
      'Google-Token': authTokens,
    };

    const reqObject = {
      method: 'GET',
      headers,
      credentials: 'include',
      mode: 'cors',
    };

    // TAG-A
    d3Fetch.json(`${apiUrl}datasetbrowser`, reqObject)
      .catch(function(err) {
        comp.offline = true;
        return (
          {
            error: 'cannot fetch data from postgres api'
          }
        );
      })
      .then(function(data) {
        window.fres = data;
        if (!data.data) {
          return data;
        } 
        return data;
      })
      .then(function(data) {
        let i = 0;
        for (; i < data.length; i++) {
          data[i].id = i;
        }
        return data;
      })

      .then(data => initCrossFilter(data))
      .then(cf => {
        window.xf = cf;
        this.cf = cf;
        this.dim1 = createDimension("System Type", d => d.system_type_name);
        this.group1 = this.dim1.createGroup();
        this.dim2 = createDimension("Organization", d => d.organization_name);
        this.group2 = this.dim2.createGroup();
        this.dim3 = createDimension("System Name", d => d.system_name);
        this.group3 = this.dim3.createGroup();
        this.dim4 = createDimension("Dataset", d => d.dataset_name);
        this.group4 = this.dim4.createGroup();
        this.dim5 = createDimension("Division", d => d.division_name); 
        this.group5 = this.dim5.createGroup();
        this.mainDim = createDimension("Dataset", d => d.dataset_name);

        this.colorBy = [
          { name: "None", key: null },
          { name: "System Type", key: "system_type_name"}
        ];

        this.sortIndex = {};

        this.group1
          .top(Infinity)
          .forEach(d => (this.sortIndex[d.key] = d.value));

        this.sortFunction = function(a, b) {
          return 0;
        };

        this.updateColorBy(0);

        window.color = this.color;
        window.colorScale = this.colorScale;

        window.dim1 = this.dim1;
        window.group1 = this.group1;
        window.dim2 = this.dim2;
        window.group2 = this.group2;
        window.dim3 = this.dim3;
        window.group3 = this.group3;
        window.dim4 = this.dim4;
        window.group4 = this.group4;
        window.dim5 = this.dim5;
        window.group5 = this.group5;

        window.mainDim = this.mainDim;

        window.d3 = d3;

        this.setState({
          xf: cf,
          isLoading: false,
          group1: this.group1.top(Infinity),
          group2: this.group2.top(Infinity),
          group3: this.group3.top(Infinity),
          group4: this.group4.top(Infinity),
          group5: this.group5.top(Infinity),
          mainDim: this.mainDim.top(Infinity),
          color: this.color,
          colorScale: this.colorScale
        });
      });
  }

  // colorByGroup: this.colorByGroup

  resetFilters() {
    this.dim1.resetFilters();
    this.dim2.resetFilters();
    this.dim3.resetFilters();
    this.dim4.resetFilters();
    this.dim5.resetFilters();
    this.setState({
      group1: this.group1.top(Infinity),
      group2: this.group2.top(Infinity),
      group3: this.group3.top(Infinity),
      group4: this.group4.top(Infinity),
      group5: this.group5.top(Infinity),
      mainDim: this.mainDim.top(Infinity),
      colorByGroup: this.colorByDimension
        ? this.colorByDimension.group().top(Infinity)
        : null,
      action: "RESETFILTER",
      filterApplied:
        this.dim1.filterApplied ||
        this.dim2.filterApplied ||
        this.dim3.filterApplied ||
        this.dim4.filterApplied ||
        this.dim5.filterApplied
    });
  }

  handleClick(ev, e, dimension) {
    //console.log(ev, e, dimension);
    if (ev.shiftKey) {
      document.getSelection().removeAllRanges();
      dimension.toggleFilter(e);
    } else {
      dimension.setFilter(e);
    }

    this.group1.top(Infinity).forEach(d => (this.sortIndex[d.key] = d.value));

    this.setState({
      group1: this.group1.top(Infinity),
      group2: this.group2.top(Infinity),
      group3: this.group3.top(Infinity),
      group4: this.group4.top(Infinity),
      group5: this.group5.top(Infinity),
      mainDim: this.mainDim.top(Infinity),
      colorByGroup: this.colorByDimension
        ? this.colorByDimension.group().top(Infinity)
        : null,
      action: "FILTER",
      filterApplied:
        this.dim1.filterApplied ||
        this.dim2.filterApplied ||
        this.dim3.filterApplied ||
        this.dim4.filterApplied ||
        this.dim5.filterApplied
    });
  }

  clickNode(e) {
    this.setState({
      clickedNode: e,
      action: "CLICKED_NODE"
    });
  }

  scrollToDataSet() {
    if (this.scrollLocation) {
      window.scrollTo(0, this.dataSetTableRef.offsetTop - 60);
      this.scrollLocation = 1 - this.scrollLocation;
    } else {
      window.scrollTo(0, 0);
      this.scrollLocation = 1 - this.scrollLocation;
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    const { isLoading, sortBy, colorBy, clusterBy, layoutBy } = this.state;
    const styleFix = {
      'marginTop': '60px',
    };

    if (isLoading) {
      return <p>Loading ...</p>;
    }

    return (
      <div className="App">
        <NavBar
          scrollDown={this.scrollToDataSet}
          scrollPos={this.scrollLocation}
          filterApplied={this.state.filterApplied}
          resetFilters={this.resetFilters}
          offline={this.offline}
        />
        <div className="container-fluid">
          {/* <div className="row">
            <div className="col-sm-1">
              <strong>By Application Type</strong>
            </div>
            <div className="col-sm-11">
              <StatCard
                data={this.state.group1}
                handleClick={this.handleClick}
                dimension={this.dim1}
              />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-sm-12">
              <hr />
            </div>
          </div> */}
          <div className="row" style={styleFix}>
            <div className="col-sm-3">
              <strong>By Organization</strong>
              <div style={{ height: "100%", width: "100%" }}>
                <HorizontalBarChart
                  data={this.state.group2}
                  handleClick={this.handleClick}
                  dimension={this.dim2}
                  categories="key"
                  values="value"
                  barCoalor={"steelblue"}
                  labels
                  xAxis
                  yAxis
                  yAxisTop
                  total={this.mainDim.top(Infinity).length}
                />
              </div>
            </div>
            <div className="col-sm-7">
              <strong>Data Sets</strong>
              <BubbleChartControl
                sortBy={sortBy}
                colorBy={colorBy}
                clusterBy={clusterBy}
                layoutBy={layoutBy}
                handleChange={this.handleBubbleControl}
              />
              {/* <div style={{ height: "100%", width: "100%" }}> */}
              <div>
                <BubbleChart
                  data={this.state.mainDim}
                  handleClick={this.handleClick}
                  dimension={this.dim5}
                  categories="key"
                  values="value"
                  barCoalor={"steelblue"}
                  color={this.state.color}
                  labels
                  xAxis
                  yAxis
                  yAxisTop
                  clickNode={this.clickNode}
                  sortFunction={this.sortFunction}
                  clusterBy={this.state.clusterBy}
                  clusterByDim={this.state.clusterByDim}
                  action={this.state.action}
                />
              </div>
            </div>
            <div className="col-sm-2">
              <div style={{ height: "50vh", width: "100%" }}>
                <strong>Legend (Color By Option)</strong>
                <Legend
                  colorScale={this.state.colorScale}
                  colorByGroup={this.state.colorByGroup}
                  colorByDimension={this.state.colorByDimension}
                  handleClick={this.handleClick}
                  total={this.mainDim.top(Infinity).length}
                />
              </div>
              <hr />
              <div style={{ height: "50vh", width: "100%" }}>
                <h6>Node Details</h6>
                <NodeDetails
                  node={this.state.clickedNode}
                  colorScale={this.state.colorScale}
                  colorByGroup={this.state.colorByGroup}
                  total={this.mainDim.top(Infinity).length}
                />
              </div>
            </div>
            {/* <div className="col-sm">
              <Facet
                data={this.state.group1}
                handleClick={this.handleClick}
                dimension={this.dim1}
              />
            </div>
            <div className="col-sm">
              <Facet
                data={this.state.group2}
                handleClick={this.handleClick}
                dimension={this.dim2}
              />
            </div> */}
          </div>
          <div className="row" ref={anchor => (this.dataSetTableRef = anchor)}>
            <div className="col-sm-12">
              <DataSetTable
                data={this.mainDim.top(
                  this.displayOptionsRows[this.state.displayNo]
                )}
                displayNo={this.state.displayNo}
                displayOptions={this.displayOptions}
                changeOption={this.changeDisplayOption}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DatasetBrowser;

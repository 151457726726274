import React from 'react';
import { Redirect } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import { useAuth } from '../context/authContext';

export default function LoginBtn() {
  const { setAuthTokens, googleClientId } = useAuth();

  const successCb = (response) => {
    setAuthTokens(response.tokenId);
    return <Redirect to="/" />;
  };

  const failureCb = (response) => {
    // eslint-disable-next-line
    console.log(`failureCb: ${JSON.stringify(response)}`);
  };

  return (
    <GoogleLogin
      clientId={googleClientId}
      buttonText="Login with your state account"
      onSuccess={successCb}
      onFailure={failureCb}
      cookiePolicy="single_host_origin"
    />
  );
}

import React from 'react';
import { Redirect } from 'react-router-dom';
import GoogleLogout from 'react-google-login';
import { useAuth } from '../context/authContext';

export default function LogoutBtn() {
  const { setAuthTokens, googleClientId } = useAuth();

  const logout = () => {
    setAuthTokens(null);
    return <Redirect to="/login" />;
  };

  const logoutFailed = () => {
    // eslint-disable-next-line
    console.log('failed to logout');
  };

  return (
    <GoogleLogout
      clientId={googleClientId}
      buttonText="Logout"
      onSuccess={logout}
      onFailure={logoutFailed}
    />
  );
}

import React, { Component } from "react";

let colorByOptions = ["None", "System Type", "Organization", "Division"];
let clusterByOptions = ["None", "System Type", "Organization", "Division"];
let layoutByOptions = ["None", "System Type", "Organization", "Division"];

export default class BubbleChartControl extends Component {
  constructor(props) {
    super(props);

    //this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {}

  render() {
    const { colorBy, sortBy, clusterBy, layoutBy, handleChange } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-sm-4">
            <div className="mr-1">Sort By</div>

            <div
              className="btn-group btn-group-sm mr-1"
              role="group"
              aria-label="First group"
            >
              {colorByOptions.map((d, i) => (
                <button
                  key={i}
                  type="button"
                  className={
                    i === sortBy || i == -sortBy
                      ? "btn btn-success btn-sm"
                      : "btn btn-secondary btn-sm"
                  }
                  onClick={d => handleChange(4, i)}
                >
                  <small>
                    {d}
                    {i === sortBy && i > 0 ? (
                      <i className="fas fa-sort-amount-down ml-1" />
                    ) : null}
                    {i === -sortBy && i > 0 ? (
                      <i className="fas fa-sort-amount-up ml-1" />
                    ) : null}
                  </small>
                </button>
              ))}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="mr-1">Color By</div>

            <div
              className="btn-group btn-group-sm mr-1"
              role="group"
              aria-label="First group"
            >
              {colorByOptions.map((d, i) => (
                <button
                  key={i}
                  type="button"
                  className={
                    i === colorBy ? "btn btn-success" : "btn btn-secondary"
                  }
                  onClick={d => handleChange(0, i)}
                >
                  <small>{d}</small>
                </button>
              ))}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="mr-1">Cluster By</div>
            <div
              className="btn-group btn-group-sm mr-1 btn-sm"
              role="group"
              aria-label="First group"
            >
              {clusterByOptions.map((d, i) => (
                <button
                  key={i}
                  type="button"
                  className={
                    i === clusterBy
                      ? "btn btn-success btn-sm"
                      : "btn btn-secondary btn-sm"
                  }
                  onClick={d => handleChange(1, i)}
                >
                  <small>{d}</small>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

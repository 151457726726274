import React, { Component } from "react";

import "./NodeDetails.css";

export default class NodeDetails extends Component {
  render() {
    const p = this.props;
    return (
      <div className="Legend" ref={anchor => (this.anchor = anchor)}>
        {p.node ? (
          <div>
            <div className="row border-bottom">
              <div className="col-sm-4">
                <strong className="small">Data Set Name</strong>
              </div>
              <div className="col-sm-8 small">{p.node.data.dataset_name}</div>
            </div>

            <div className="row border-bottom">
              <div className="col-sm-4">
                <strong className="small">Data Set Description</strong>
              </div>
              <div className="col-sm-8 small">
                {p.node.data.dataset_description !== "null"
                  ? p.node.data.dataset_description
                  : "Unknown"}
              </div>
            </div>

            <div className="row border-bottom">
              <div className="col-sm-4">
                <strong className="small">Organization</strong>
              </div>
              <div className="col-sm-8 small">{p.node.data.organization_name}</div>
            </div>

            <div className="row border-bottom">
              <div className="col-sm-4">
                <strong className="small">Division</strong>
              </div>
              <div className="col-sm-8 small">{p.node.data.division_name}</div>
            </div>

            <div className="row border-bottom">
              <div className="col-sm-4">
                <strong className="small">System Name</strong>
              </div>
              <div className="col-sm-8 small">{p.node.data.system_name}</div>
            </div>

            <div className="row">
              <div className="col-sm-4">
                <strong className="small">Type</strong>
              </div>
              <div className="col-sm-8 small">{p.node.data.system_type_name}</div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

import React, { Component } from "react";

import "./DataSetTable.css";

export default class DataSetTable extends Component {
  constructor(props) {
    super(props);

    this.displayNo = props.displayNo;
    this.changeOption = this.changeOption.bind(this);
  }

  changeOption(e) {
    this.props.changeOption(e.target.selectedIndex);
  }

  render() {
    const { data } = this.props;
    let comp = this;
    return (
      <div
        className="-"
        ref={anchor => (this.anchor = anchor)}
        style={{ overflowY: "auto", overflowX: "hidden", height: "100vh" }}
      >
        Display
        <select onChange={this.changeOption}>
          {this.props.displayOptions.map((d, i) => (
            <option
              key={i}
              id={i}
              defaultValue={comp.displayNo === i ? "selected" : ""}
            >
              {d}
            </option>
          ))}
        </select>
        # of Data Sets
        <table className="table table-sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Data Set</th>
              <th scope="col">Data Set Description</th>
              <th scope="col">Organization</th>
              <th scope="col">Division</th>
              <th scope="col">System</th>
              <th scope="col">System Type</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td style={{ maxWidth: "250px" }}>{d.dataset_name}</td>
                <td style={{ maxWidth: "250px" }}>{d.dataset_description}</td>
                <td>{d.organization_name}</td>
                <td>{d.division_name}</td>
                <td>{d.system_name}</td>
                <td>{d.system_type_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

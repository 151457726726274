import React, { Component } from "react";
import * as d3 from "d3";

import "./Legend.css";

export default class Legend extends Component {
  constructor(props) {
    super(props);
    //console.log("Legend:", props);
    this.resize = false;
    // If onClickNode function provided via props, call it when nodes are clicked/selected
    if (props.onClickNode) this.onClickNodeHandler = props.onClickNode;
    else this.onClickNodeHandler = false;

    this.createChart = this.createChart.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onResize = this.onResize.bind(this);
    this.x = this.x.bind(this);
    this.y = this.y.bind(this);
  }

  x(d) {
    return d[this.props.x];
  }

  y(d) {
    return d[this.props.y];
  }

  onResize() {
    // Set flag to resize = true to drive a re-draw in createChart
    this.resize = true;
    this.createChart(this.props.data);
  }

  componentDidMount() {
    // Add resize event handler to re-size and re-draw when window is resized
    window.addEventListener("resize", this.onResize, false);
    this.createChart(this.props.data);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize, false);
  }

  componentDidUpdate() {
    this.createChart(this.props.data);
  }

  handleClick(e) {
    // console.log(e, e.key, this.props.colorByDimension);
    this.props.handleClick(
      d3.event,
      e.key ? e.key : e,
      this.props.colorByDimension
    );
  }

  createChart(chartData) {
    const anchor = this.anchor;
    const header = this.headerNode;

    const parentWidth = this.anchor.clientWidth;
    const parentHeight = this.anchor.clientHeight;

    const margin = { top: 50, right: 15, bottom: 60, left: 0 };
    const width = parentWidth - margin.left - margin.right;
    const svgref = this.svgref;
    const node = this.svgref;

    const x = this.x;
    const y = this.y;
    const series = this.props.series;

    const color = this.props.color;

    const colors = [
      "#ffffd9",
      "#edf8b1",
      "#c7e9b4",
      "#7fcdbb",
      "#41b6c4",
      "#1d91c0",
      "#225ea8",
      "#253494",
      "#081d58"
    ];
    const buckets = colors.length;

    const comp = this;

    d3.select(node)
      .style("width", parentWidth)
      .style("height", (this.props.colorByGroup.length + 1) * 20 + 50);

    d3.select(node)
      .select("g.content")
      .remove();

    const g = d3
      .select(node)
      .selectAll("g.content")
      .data([0])
      .enter()
      .append("g")
      .attr("class", "content")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    //console.log(chartData);

    const yScale = d3
      .scaleBand()
      .domain(
        this.props.colorByGroup
          ? this.props.colorByGroup
          : [{ key: "Dataset" }].map(d => d.key)
      )
      .range([0, (this.props.colorByGroup.length + 1) * 20 + 50])
      .padding(0.1);

    g.selectAll("text").remove();

    let legend = g.selectAll("text").data(
      this.props.colorByGroup
        ? this.props.colorByGroup
        : // .filter(
          //     d => comp.props.colorByDimension.members[d].filtered
          //   )
          [{ key: "Dataset" }],
      d => d.key
    );

    legend
      .enter()
      .append("rect")
      .attr("x", 0)
      .attr("y", (d, i) => {
        return i * 20;
      })
      .attr("width", 40)
      .attr("height", 15)
      .style("stroke", "grey")
      .attr("style", "cursor:pointer")
      .style("fill", function(d, i) {
        //console.log(comp.props.colorByDimension, d);
        return !comp.props.colorByDimension.members[d.key].filtered &&
          comp.props.colorByDimension.filterApplied
          ? "lightgrey"
          : comp.props.colorScale(d.key);
      })
      .on("click", d => this.handleClick(d));

    legend
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("style", "cursor:pointer")
      .on("click", d => this.handleClick(d))
      .text(function(d) {
        return (
          d.key +
          " - " +
          d.value +
          " (" +
          ((d.value / comp.props.total) * 100).toFixed(1) +
          "%)"
        );
      })
      .attr("x", 45)
      //.attr("y", d => yScale(d.key));
      .attr("y", (d, i) => {
        return i * 20 + 10;
      });
  }

  render() {
    return (
      <div
        className="Legend"
        ref={anchor => (this.anchor = anchor)}
        style={{ overflowY: "hidden scroll", overflowX: "hidden" }}
      >
        <svg
          ref={svgref => (this.svgref = svgref)}
          width={this.width}
          height={this.height}
        />
      </div>
    );
  }
}

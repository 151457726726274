import crossfilter from "crossfilter/crossfilter.min.js";

let dimensions = [];
let groups = [];

let cf = null;

export function initCrossFilter(data) {
  //console.log(`initCrossFilter data: ${JSON.stringify(data, null, 2)}`);
  var new_data = data.map(function(iter) {
    return {
      dataset_id: iter.dataset_id,
      dataset_description: iter.dataset_description,
      dataset_name: iter.dataset,
      division_name: iter.division,
      id: iter.id,
      organization_name: iter.organization,
      owner_name: iter.owner,
      system_name: iter.system_name,
      system_type_name: iter.system_type,
    }
  });
  cf = crossfilter.crossfilter(new_data);
  return cf;
}

export function createDimension(name, d) {
  let dima = cf.dimension(d);

  dima.name = name;
  dima.filterApplied = 0;

  dima.createGroup = function(d) {
    var group = this.group();
    groups.push(group);
    return group;
  };

  let dimGroup = dima.group();
  let dimMembers = {};

  dimGroup.all().forEach(function(e) {
    dimMembers[e.key] = { filtered: 0 };
  });

  dimGroup.dispose();

  dima.members = dimMembers;
  //dima.filterApplied = 0;

  dima.toggleFilter = function(m) {
    //dima.members[m].filtered = 1 - dima.members[m].filtered;
    //dima.filterFunction(dima._filterFunction);

    if (!dima.filterApplied) {
      for (var propt in dima.members) {
        dima.members[propt].filtered = 1;
      }

      dima.members[m].filtered = 0;

      dima.filterApplied = 1;
    } else {
      dima.members[m].filtered = 1 - dima.members[m].filtered;
    }
    dima.filterFunction(dima._filterFunction);
  };

  dima.resetFilters = function() {
    for (var propt in dima.members) {
      dima.members[propt].filtered = 0;
    }
    dima.filterApplied = 0;
    dima.filterAll();
  };

  dima.setFilter = function(m) {
    // console.log(m);
    // console.log(">" + m + "<");
    // console.log(dima.members[m]);
    if (dima.members[m].filtered === 1) {
      dima.members[m].filtered = 0;
      dima.filterApplied = 0;
      dima.filterAll();
    } else {
      for (var propt in dima.members) {
        dima.members[propt].filtered = 0;
      }

      dima.members[m].filtered = 1;
      dima.filterApplied = 1;
      dima.filterFunction(dima._filterFunction);
      return;
    }
    return;
  };

  dima._filterFunction = function(d) {
    // return dima.filtersApplied ? dima.members[d].filtered : 1;
    return dima.members[d].filtered;
  };

  dimensions.push(dima);

  return dima;
}

export function createGroup(d) {
  var group;

  group = groups.push(group);
  return group;
}

import React, { Component } from "react";
import * as d3 from "d3";

import "./HorizontalBarChart.css";

let formatValue = d3.format(".3s");

export default class HorizontalBarChart extends Component {
  constructor(props) {
    super(props);
    this.resize = false;

    if (props.onClickNode) this.onClickNodeHandler = props.onClickNode;
    else this.onClickNodeHandler = false;

    this.createChart = this.createChart.bind(this);
    this.onResize = this.onResize.bind(this);
    this.x = this.x.bind(this);
    this.y = this.y.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  x(d) {
    return d[this.props.values];
  }

  y(d) {
    return d[this.props.categories];
  }

  onResize() {
    //console.log('resize()');

    // Set flag to resize = true to drive a re-draw in createChart
    this.resize = true;
    this.createChart(this.props.data);
  }

  componentDidMount() {
    // Add resize event handler to re-size and re-draw when window is resized
    window.addEventListener("resize", this.onResize, false);
    this.createChart(this.props.data);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize, false);
  }

  componentDidUpdate() {
    this.createChart(this.props.data);
  }

  handleClick(e) {
    this.props.handleClick(d3.event, e.key ? e.key : e, this.props.dimension);
  }

  createChart(chartData) {
    const options = {
      yAxisTop: this.props.yAxisTop
    };

    const comp = this;

    const anchor = this.anchor;

    const parentWidth = this.anchor.clientWidth;
    const parentHeight = this.anchor.clientHeight;
    const nodeParentHeight = this.nodeParent.clientHeight;

    const headerHeight = 50;

    const margin = { top: 40, right: 15, bottom: 10, left: 150 };
    let height = nodeParentHeight - margin.top - margin.bottom - 5;

    if (height / chartData.length < 23) height = chartData.length * 23;
    if (height / chartData.length > 23) height = chartData.length * 23;

    const width = parentWidth - margin.left - margin.right;
    const svgref = this.svgref;

    const color = function() {
      if (!comp.props.barColor && !comp.props.colorScale) {
        return function(d, i) {
          if (!comp.props.dimension.filterApplied) {
            return "#004582";
          } else {
            if (comp.props.dimension.members[d].filtered) return "#004582";
            else return "lightgrey";
          }
        };
      }
      if (comp.props.colorScale) {
        if (!Array.isArray(comp.props.colorScale)) {
          const cs = d3.scaleOrdinal().domain(chartData.map(d => comp.y(d)));

          if (comp.props.colorScaleReverse)
            cs.range(
              d3
                .quantize(
                  t => comp.props.colorScale(t * 0.8 + 0.1),
                  chartData.length
                )
                .reverse()
            );
          else
            cs.range(
              d3.quantize(
                t => comp.props.colorScale(t * 0.8 + 0.1),
                chartData.length
              )
            ); //.reverse());

          return function(d, i) {
            return cs(d);
          };
        } else {
          return function(d, i) {
            return comp.props.colorScale[i];
          };
        }
      }
      if (comp.props.barColor)
        return function(d, i) {
          return comp.props.barColor;
        };

      return function() {
        return "red";
      };
    };

    const colorScale = color();

    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(chartData.map(this.x)) * 1.3])
      .range([0, width]);

    const xAxis = options.yAxisTop ? d3.axisTop() : d3.axisBottom();

    xAxis.ticks(5).scale(xScale);

    //console.log(height);

    const yScale = d3
      .scaleBand()
      .domain(chartData.map(d => this.y(d)))
      .range([0, height])
      .padding(0.1);

    const yAxis = d3
      .axisLeft()
      .scale(yScale)
      .tickFormat(function(d) {
        return d.substring(0, 26);
      });

    const node = this.svgref;

    const header = this.svgrefHeader;

    d3.select(node)
      .style("width", parentWidth)
      .style("height", height + margin.top + margin.bottom);

    d3.select(header)
      .style("width", parentWidth)
      .style("height", headerHeight);

    d3.select(node)
      .selectAll("g.content")
      .data([0])
      .enter()
      .append("g")
      .attr("class", "content")
      .attr("transform", "translate(" + margin.left + "," + 0 + ")");

    d3.select(header)
      .selectAll("g.content")
      .data([0])
      .enter()
      .append("g")
      .attr("class", "content")
      .attr(
        "transform",
        "translate(" + margin.left + "," + (headerHeight - 1) + ")"
      );

    d3.select(node)
      .select("g.content")
      .selectAll("g.yaxis")
      .data([0])
      .enter()
      .append("g")
      .attr("class", "yaxis")
      .call(yAxis);

    d3.select(node)
      .select("g.content")
      .selectAll("g.yaxis")
      //.attr("x", d => (d.value > 0 ? null : -200))
      .transition()
      .duration(250)
      .call(yAxis)
      .selectAll("text")
      //.data(chartData)
      //.enter()
      .attr("x", (d, i) => {
        //console.log(d);
        return chartData[i].value > 0 ? -10 : -200;
      });

    d3.select(node)
      .select("g.content")
      .selectAll("g.yaxis")
      .selectAll(".tick")
      //.on('click', this.clickYAxis);
      .attr("style", "cursor:pointer")
      .on("click", d => this.handleClick(d));

    d3.select(header)
      .select("g.content")
      .selectAll("g.xaxis")
      .remove();

    d3.select(header)
      .select("g.content")
      .selectAll("g.xaxis")
      .data([0])
      .enter()
      .append("g")
      .attr("class", "xaxis")
      .attr("transform", "translate(0," + (options.yAxisTop ? 0 : height) + ")")
      .call(xAxis);

    d3.select(node)
      .select("g.content")
      .selectAll("rect")
      .data(this.props.data) //, this.x)
      .enter()
      .append("rect");

    d3.select(node)
      .selectAll("rect")
      .exit()
      .remove();

    d3.select(node)
      .selectAll("rect")
      .attr("style", "cursor:pointer")
      .attr("class", "Bar")
      .data(this.props.data) //, this.x)
      //.attr("x", 1)
      .attr("fill", (d, i) => colorScale(this.y(d), i))
      .attr("height", yScale.bandwidth())
      .attr("x", this.x(0)) //d => xScale(this.x(d)))
      .on("click", d => this.handleClick(d))
      .transition()
      .duration(250)
      .attr("y", d => yScale(this.y(d)))
      //.attr('height', d => height - yScale(this.y(d)))
      .attr("width", d => xScale(this.x(d)));

    d3.select(node)
      .select("g.content")
      .selectAll("g.barlabel")
      .selectAll("text")
      .remove();

    d3.select(node)
      .select("g.content")
      .append("g")
      .attr("class", "barlabel")
      //.attr("fill", "grey")
      .attr("text-anchor", "begin")
      //.style("font", "12px sans-serif")
      .selectAll("text")
      .data(this.props.data)
      .enter()
      .append("text")
      //.transition()
      //.duration(250)
      .attr("x", d => xScale(this.x(d)) + 4)
      .attr("y", d => yScale(this.y(d)) + yScale.bandwidth() / 2)
      .attr("dy", "0.35em")
      .text(d => {
        return this.x(d) > 0
          ? this.x(d) +
              " (" +
              ((this.x(d) / comp.props.total) * 100).toFixed(1) +
              "%)"
          : null;
      });
  }

  render() {
    return (
      <div
        className="HorizontalBarChart"
        ref={anchor => (this.anchor = anchor)}
      >
        <div style={{ height: "50px" }}>
          <svg
            ref={svgref => (this.svgrefHeader = svgref)}
            width={this.width}
            height={"50px"}
          />
        </div>
        <div
          style={{ overflowY: "auto", overflowX: "hidden", height: "90vh" }}
          ref={anchor => (this.nodeParent = anchor)}
        >
          <svg
            ref={svgref => (this.svgref = svgref)}
            width={this.width}
            height={this.height}
          />
        </div>
      </div>
    );
  }
}
